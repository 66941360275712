
import { defineComponent, ref } from 'vue'
import TmEmptyState from '@/components/shared/TmEmptyState.vue'
import TmButton from '@/components/shared/TmButton.vue'
import ContactsAllContactsTable from '@/components/pages/contacts/allContacts/ContactsAllContactsTable.vue'
import { getTableData } from '@/services/tableService'
import type { TableHeaders } from '@/definitions/shared/types'
import type { ContactLine } from '@/definitions/contacts/allContacts/types'
import { useModes } from '@/compositions/modes'

export default defineComponent({
  components: {
    ContactsAllContactsTable,
    TmEmptyState,
    TmButton,
  },
  setup() {
    const { isEmptyMode } = useModes()
    const tableHeaders = ref<TableHeaders[]>([
      { text: 'Full name', value: 'full-name-slot' },
      { text: 'Phone', value: 'phone-slot', width: '17%' },
      { text: 'Email', value: 'email-slot', width: '18%' },
      { text: 'Organization', value: 'organization-slot', width: '20%' },
      { text: 'Type', value: 'type-slot', width: '14%' },
    ])
    const tableItems = ref<ContactLine[]>(getTableData('contactsAllContacts'))

    return {
      isEmptyMode,
      tableHeaders,
      tableItems,
    }
  },
})
